import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

const Loadable = (Component: ElementType) => (props: any) =>  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { element: <ComingSoon />, index: true }
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
